<template>
  <div class="blank">
    <div class="pay-message">
      <div style="margin-left: 28%; margin-top: 12%">
        <p>订单提交成功, 请尽快付款! 订单号:{{ this.orderItem }}</p>
        <p>请使用支付宝扫码支付!!!</p>
      </div>
    </div>

    <div class="pay-png">
      <img :src="this.payImg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderItem: "",
      payImg: "",
      state: "10",
      timer: "",
    };
  },
  //    watch:{
  //      async state() {
  //           const res = await this.$http({
  //               method:'get',
  //               url:'front/pay/queryOrderPayStatus?orderNo=' + this.orderItem
  //           })
  //           console.log(res)
  //           this.state = res.data.data.data
  //           if (this.state == '20') {
  //               console.log(1)
  //           }
  //       }
  //    },
  methods: {
    getRouteData() {
      this.orderItem = this.$route.query.name;
      console.log(this.orderItem);
    },
    async getPayImg() {
      const res = await this.$http({
        method: "post",
        url: "front/pay/scanCodePay",
        data: {
          orderNo: this.orderItem,
        },
      });
      this.payImg = res.data.data.qrUrl;
    },
    //    定时器
    async get() {
      const res = await this.$http({
        method: "get",
        url: "front/pay/queryOrderPayStatus?orderNo=" + this.orderItem,
      });
      if (res.data.data == "20") {
        this.$router.push("/orderSucc");
        // clearInterval(this.timer);
      }
      //   console.log(res.data.data)
    },
  },
  mounted() {
    // 定时器5秒查询一次看状态码变化
    this.timer = setInterval(this.get, 5000);
  },
  created() {
    this.getRouteData();
    this.getPayImg();
  },
};
</script>

<style>
body {
  margin: 0px;
  padding: 0px;
}

.blank {
  width: 100%;
  height: 753px;
  background: url("../assets/images/pay-background.png") no-repeat;
  background-size: cover;
}
.pay-message {
  width: 70%;
  height: 30%;
  margin: auto;
  padding-top: 50px;
}

.pay-png {
  margin: auto;
  width: 200px;
  height: 200px;
  padding-top: 3%;
}
</style>